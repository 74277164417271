(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://' + w.intercomSettings.app_id + '.intercom-chat.com/shim.latest.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
const liveChatButtons = document.querySelectorAll('.live-chat-button');

for (const button of liveChatButtons) {
  button.addEventListener('click', (event) => {
    window.Intercom('show');
  });
}

if (window.location.search.includes('livechat=true')) {
  window.Intercom('show');;
}
